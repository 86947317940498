@import '@aurora/shared-client/styles/_variables.pcss';
@import '@aurora/shared-client/styles/mixins/_screenreader.pcss';

.lia-kudo-btn {
  .lia-kudo-text {
    margin-left: 5px;
  }

  &.lia-is-kudoed {
    .lia-icon {
      fill: currentColor;
    }
    .lia-kudo-text {
      color: currentColor;
    }
  }
}

.lia-icon-animate-in {
  animation: liaKudosFlick var(--lia-timing-slow) var(--lia-timing-function);
}

.lia-kudo-divider {
  margin: 0;
  background-color: var(--lia-bs-gray-600);
}

@media (--lia-breakpoint-down-sm) {
  .lia-kudo-btn,
  .lia-kudo-count {
    padding-left: 5px;
    padding-right: 5px;
  }

  .lia-kudo-text {
    @include sr-only();
  }

  .lia-kudo-divider {
    display: none;
  }
}

@keyframes liaKudosFlick {
  0% {
    transform: rotate(0deg) scale(1);
  }
  40% {
    transform: rotate(25deg) scale(0.7);
  }
  70% {
    transform: rotate(0deg) scale(1.3);
  }
  100% {
    transform: rotate(0deg) scale(1);
  }
}

.lia-vote-count {
  padding: 0 15px;
  background-color: var(--lia-bs-gray-300);
  font-size: var(--lia-font-size-xs);
  font-weight: var(--lia-bs-font-weight-bold);
  color: var(--lia-bs-gray-800);
  border-radius: var(--lia-bs-border-radius-sm) var(--lia-bs-border-radius-sm) 0 0;
}

.lia-vote-count-kudoed.lia-vote-count-kudoed {
  background-color: var(--lia-idea-color);
  color: var(--lia-d-idea-text-color, var(--lia-bs-white));
}

.lia-vote-count-card {
  display: block;
  padding: 7px 20px;
  font-size: var(--lia-bs-h6-font-size);
  font-weight: var(--lia-font-weight-md);
  color: var(--lia-bs-gray-800);
  background-color: var(--lia-bs-gray-300);
  border-radius: 0 var(--lia-bs-border-radius-sm) var(--lia-bs-border-radius-sm) 0;
}

.lia-vote-text {
  font-size: var(--lia-bs-small-font-size);
  color: var(--lia-bs-gray-800);
  font-weight: var(--lia-font-weight-md);
  padding-right: 5px;
}

.lia-vote-text-color {
  color: var(--lia-d-idea-text-color, var(--lia-bs-white));
}

.lia-vote-text-kudoed.lia-vote-text-kudoed {
  color: var(--lia-bs-gray-900);
  font-weight: var(--lia-bs-font-weight-bold);
}

.lia-vote-text-icon {
  display: flex;
  align-items: center;
  border-radius: var(--lia-bs-border-radius-sm) 0 0 var(--lia-bs-border-radius-sm);
  padding: 0 20px;
  height: 100%;
}

.lia-vote-button-inline {
  display: grid;
  border: 0;
  background-color: var(--lia-bs-gray-200);
  padding: 0;

  &:hover,
  &:focus {
    box-shadow: none;
  }
  &:disabled {
    opacity: 1;
  }
}

.lia-button.lia-button {
  cursor: not-allowed;
  padding: 0;
  border: 0;
  &:hover,
  &:focus {
    box-shadow: none;
  }
  &:disabled {
    opacity: 1;
  }
}
.lia-vote-card {
  background-color: var(--lia-bs-gray-200);
}

.lia-vote-standard {
  background-color: var(--lia-idea-color);
}

.lia-vote-completed {
  background-color: var(--lia-bs-gray-400);
  cursor: not-allowed;
}

.lia-vote-icon {
  margin: 3px auto;
}

.lia-vote-standard,
.lia-vote-card {
  .lia-vote-icon {
    fill: var(--lia-d-idea-text-color, var(--lia-bs-white));
  }
}

.lia-vote-count-button {
  border: 0;
  margin: 0;
  padding: 0;
  &:hover,
  &:focus {
    box-shadow: none;
    text-decoration: none;
  }
}
